<template>
  <validation-observer v-if="order" v-slot="{ invalid }" tag="div">
    <v-card flat :loading="loading" outlined>
      <v-card-title class="pb-2">
        {{ $t('customer.orders.orderItems') }}
      </v-card-title>
      <v-card-text class="d-flex flex-column">
        <validation-provider
          rules="required"
          :name="$t('customer.orders.currency')"
          v-slot="{ errors }"
        >
          <cz-currency-picker
            v-model="order.currency"
            :label="$t('customer.orders.currency')"
            :items="currencyTypes"
            :error-messages="errors"
            :placeholder="$t('customer.orders.selectCurrency')"
            outlined
            dense
            style="max-width: 400px"
            :readonly="readOnlyMode"
          />
        </validation-provider>
        <v-form :disabled="loading" class="flex-grow-1">
          <cz-data-table
            hide-default-footer
            :no-data-text="$t('customer.orders.orderItemsNoData')"
            :columns="columns"
            :items="order ? order.orderItems : []"
            class="table"
            :height="tableHeight"
          >
            <template #body.append>
              <div class="py-4" v-visible="canAddItem">
                <cz-button
                  color="primary"
                  :icon-src="mdiPlusCircleOutline"
                  :title="$t('customer.orders.orderItemsPage.newItem')"
                  @click="onAddItem"
                  :disabled="!order.currency"
                />
              </div>
            </template>
            <template #footer>
              <amount-summary
                v-if="order.currency"
                :vat-amount="order.vatAmount"
                :net-amount="order.netAmount"
                :total-amount="order.totalAmount"
                :vat-percent="vendorVatPercent"
              />
            </template>
            <template #item.itemCode="{ item, index, header }">
              <validation-provider
                rules="required"
                :name="header.text"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="item.catalogItemId"
                  dense
                  :readonly="!item.mode"
                  :items="catalogItems"
                  hide-details
                  :placeholder="$t('customer.orders.orderItemsPage.selectItem')"
                  item-text="itemCode"
                  :error-messages="errors"
                  item-value="_id"
                  @change="onItemChange($event, index)"
                  :clearable="!readOnlyMode"
                >
                  <template #item="{ item }">
                    <span>{{ `${item.itemCode} - ${item.itemName}` }}</span>
                  </template>
                </cz-autocomplete>
              </validation-provider>
            </template>
            <template #item.supplyDate="{ item, header }">
              <validation-provider
                v-if="item.catalogItemId"
                rules="required"
                :name="header.text"
                v-slot="{ errors }"
              >
                <cz-new-date-picker
                  v-model="item.supplyDate"
                  hide-details
                  :readonly="!item.mode"
                  dense
                  open-menu-on-click
                  :error-messages="errors"
                />
              </validation-provider>
            </template>
            <template #item.quantity="{ item, header }">
              <validation-provider
                v-if="item.catalogItemId"
                rules="required|min:1"
                :name="header.text"
              >
                <cz-input
                  v-model="item.quantity"
                  hide-details
                  :readonly="!item.mode"
                  dense
                  outlined
                  type="number"
                />
              </validation-provider>
            </template>
            <template #item.pricePerUnit="{ item, header }">
              <validation-provider
                v-if="item.catalogItemId"
                rules="required"
                :name="header.text"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="item.pricePerUnit"
                  :error-messages="errors"
                  dense
                  hide-details
                  type="number"
                  :readonly="
                    item.catalogItem.priceType ===
                      PriceType.WITH_PRICE_UNCHANGEABLE || !item.mode
                  "
                />
              </validation-provider>
            </template>
            <template #item.totalAmount="{ item }">
              <div v-if="item.quantity > 0">
                {{
                  formatAmountWithCurrency(
                    parseFloat(item.pricePerUnit) * parseInt(item.quantity),
                    order.currency
                  )
                }}
              </div>
            </template>
            <template #item.actions="{ item, index }" v-if="!readOnlyMode">
              <div
                v-if="!!item.mode"
                class="d-flex align-center"
                style="column-gap: 10px"
              >
                <cz-button
                  v-if="item.mode === 'create'"
                  :title="$t('common.add')"
                  color="primary"
                  :icon-src="mdiPlus"
                  @click="onAddItemToOrder(item, index)"
                  :disabled="!canUpdateItem(item)"
                />
                <cz-button
                  v-else-if="item.mode === 'edit'"
                  :title="$t('common.update')"
                  color="primary"
                  :icon-src="mdiCheck"
                  @click="onAddItemToOrder(item, index)"
                  :disabled="!canUpdateItem(item)"
                />
                <cz-button
                  color="primary"
                  :icon-src="mdiCloseCircleOutline"
                  text
                  @click="onCancelItemUpdate(item, index)"
                />
              </div>
              <div v-else class="d-flex align-center">
                <cz-button
                  text
                  :icon-src="mdiPencil"
                  :icon-size="22"
                  color="primary"
                  small
                  @click="onEditOrderItem(index)"
                />
                <cz-button
                  text
                  :icon-src="mdiTrashCanOutline"
                  :icon-size="22"
                  small
                  color="primary"
                  @click="onDeleteOrderItem(index)"
                />
              </div>
            </template>
          </cz-data-table>
        </v-form>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
import {
  CzDataTable,
  CzButton,
  CzInput,
  CzAutocomplete,
  CzNewDatePicker,
  CzFormField,
  CzDatePicker,
  CzCurrencyPicker
} from '@/components';
import AmountSummary from '@/domain/shared/components/AmountSummary.vue';
import {
  mdiPlusCircleOutline,
  mdiPlusOutline,
  mdiPlus,
  mdiCheck,
  mdiCancel,
  mdiCloseCircleOutline,
  mdiTrashCanOutline,
  mdiPencil
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import PriceType from '@/domain/customer/types/PriceType';
import currencyTypes from '@/shared/types/CurrencyTypes';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';

const cellClass = 'py-2';
export default {
  name: 'OrderItems',
  components: {
    CzNewDatePicker,
    CzDataTable,
    CzAutocomplete,
    CzCurrencyPicker,
    CzDatePicker,
    CzFormField,
    CzButton,
    CzInput,
    AmountSummary
  },
  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    readOnlyMode: {
      type: Boolean
    },
    catalogItems: {
      type: Array,
      default: () => []
    },
    vendorVat: {
      type: Number,
      required: true
    },
    tableHeight: {
      type: [String, Number]
    }
  },
  computed: {
    orderItems() {
      return this.order?.orderItems || [];
    },
    vendorVatPercent() {
      if (this.vendorVat > 0) {
        return `${this.vendorVat * 100}%`;
      }
      return this.$t('customer.orders.noVat');
    },
    canAddItem() {
      return (
        !this.readOnlyMode &&
        this.order?.orderItems?.filter((item) => !!item.mode).length === 0
      );
    }
  },
  created() {
    this.order = cloneDeep(this.value);
    if (!this.order?.orderItems) {
      this.order.orderItems = [];
    }
  },
  data() {
    return {
      PriceType,
      mdiCancel,
      mdiPlusOutline,
      currencyTypes,
      mdiPlusCircleOutline,
      mdiPlus,
      mdiCheck,
      mdiCloseCircleOutline,
      mdiTrashCanOutline,
      mdiPencil,
      order: null,
      editedOrderItems: {},
      columns: [
        {
          text: this.$t('customer.orders.orderItemsPage.rowNumber'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'rowNumber',
          cellClass,
          sortable: false,
          width: 120
        },
        {
          text: this.$t('customer.orders.orderItemsPage.itemNumber'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'itemCode',
          cellClass,
          sortable: false,
          width: 200
        },
        {
          text: this.$t('customer.orders.orderItemsPage.itemName'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          cellClass,
          value: 'catalogItem.itemName',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('customer.orders.orderItemsPage.supplyDate'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'supplyDate',
          cellClass,
          sortable: false,
          width: 200
        },
        {
          text: this.$t('customer.orders.orderItemsPage.unitOfMeasure'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'catalogItem.itemUnitOfMeasureText',
          cellClass,
          sortable: false,
          width: 100
        },
        {
          text: this.$t('customer.orders.orderItemsPage.quantity'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'quantity',
          cellClass,
          sortable: false,
          width: 100
        },
        {
          text: this.$t('customer.orders.orderItemsPage.price'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'pricePerUnit',
          cellClass,
          sortable: false,
          width: 100
        },
        {
          text: this.$t('customer.orders.orderItemsPage.totalAmount'),
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'totalAmount',
          cellClass,
          width: 150,
          sortable: false
        },
        {
          text: '',
          class: 'font-weight-semibold text-subtitle-1 primary--text',
          value: 'actions',
          cellClass,
          sortable: false
        }
      ]
    };
  },
  methods: {
    formatAmountWithCurrency,
    onAddItem() {
      const _order = cloneDeep(this.order);

      if (!_order?.orderItems) {
        _order.orderItems = [];
      }

      let rowNumber = 10;

      if (_order.orderItems.length) {
        // if order already contain item, then we need to get the row with the max rowNumber
        // and add 10 to it
        _order.orderItems.forEach((item) => {
          if (item.rowNumber > rowNumber) {
            rowNumber = item.rowNumber;
          }
        });

        rowNumber = rowNumber + 10;
      }

      // const rowNumber = (_order.orderItems.length + 1) * 10;

      _order.orderItems.push({
        rowNumber,
        catalogItemId: '',
        itemCode: '',
        itemName: '',
        supplyDate: this.order.issueDate,
        itemUnitOfMeasure: undefined,
        quantity: undefined,
        itemPrice: undefined,
        totalAmount: undefined,
        mode: 'create'
      });

      this.order = _order;
    },

    canUpdateItem(item) {
      return (
        item.catalogItemId &&
        item.supplyDate &&
        item.quantity &&
        item.pricePerUnit
      );
    },

    onAddItemToOrder(item, index) {
      item.mode = '';
      if (this.editedOrderItems[index]) {
        delete this.editedOrderItems[index];
      }
    },
    onCancelItemUpdate(item, index) {
      if (item.mode === 'create') {
        this.onDeleteOrderItem(index);
      } else if (item.mode === 'edit') {
        const editedItem = this.editedOrderItems[index];
        editedItem.mode = '';
        const _order = cloneDeep(this.order);
        _order.orderItems[index] = cloneDeep(editedItem);
        delete this.editedOrderItems[index];
        this.order = _order;
      }
    },

    onItemChange(value, index) {
      // extract full catalog item from list
      const catalogItem = this.catalogItems.find(
        (cItem) => cItem._id === value
      );
      const _order = cloneDeep(this.order);
      if (catalogItem) {
        _order.orderItems[index].pricePerUnit = catalogItem.itemPrice;
        _order.orderItems[index].catalogItem = catalogItem;
      } else {
        _order.orderItems[index].itemName = '';
        _order.orderItems[index].catalogItem = null;
        _order.orderItems[index].supplyDate = null;
        _order.orderItems[index].quantity = undefined;
        _order.orderItems[index].itemPrice = undefined;
        _order.orderItems[index].totalAmount = undefined;
        _order.orderItems[index].netAmount = undefined;
        _order.orderItems[index].vatAmount = undefined;
        _order.orderItems[index].catalogItem = null;
        _order.orderItems[index].itemUnitOfMeasure = undefined;
      }

      this.$nextTick(() => {
        this.order = _order;
      });
    },
    onDeleteOrderItem(index) {
      this.order.orderItems.splice(index, 1);
    },
    onEditOrderItem(index) {
      const _order = cloneDeep(this.order);
      _order.orderItems[index].mode = 'edit';
      this.order = _order;
      this.editedOrderItems[index] = cloneDeep(_order.orderItems[index]);
    },
    calculateOrderTotalAmount() {
      if (!this.order?.orderItems?.length) {
        this.order.totalAmount = 0;
        this.order.netAmount = 0;
        this.order.vatAmount = 0;
      }
      this.order.netAmount = 0;
      this.order.orderItems?.forEach((item) => {
        if (!item.mode && item.quantity && parseInt(item.quantity) > 0) {
          const netAmount =
            parseFloat(item.quantity) * parseFloat(item.pricePerUnit);
          this.order.netAmount += netAmount;
        }
      });
      this.order.vatAmount = parseFloat(this.order.netAmount) * this.vendorVat;
      this.order.totalAmount = this.order.netAmount + this.order.vatAmount;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.order)) {
          this.order = cloneDeep(value);
        }
      }
    },
    order: {
      deep: true,
      handler(value) {
        // if (!isEqual(value, this.value)) {
        //   this.calculateOrderTotalAmount();

        // }
        this.$emit('input', value);
      }
    },
    vendorVat: {
      handler() {
        if (!this.readOnlyMode) {
          this.calculateOrderTotalAmount();
        }
      }
    },
    'order.orderItems': {
      deep: true,
      handler() {
        if (!this.readOnlyMode) {
          this.calculateOrderTotalAmount();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
::v-deep .table .v-table tbody td {
  border: none !important;
}
// ::v-deep .table .v-table tbody tr:not(:last-child) {
//   border-bottom: none !important;
// }
</style>
